<template>
  <div class="p4b__parent">
    <div
      v-if="showMessage"
      class="p4b__content-section"
    >
      <div class="p4b__onboarding-logo">
        <img
          :src="logoImg"
          alt="Logo"
        >
      </div>
      <div
        v-if="showMessage"
        class="p4b__onboarding-content"
      >
        <div class="p4b__onboarding-icon">
          <feather-icon
            size="80"
            icon="HeartIcon"
          />
        </div>
        <div class="p4b__onboarding-title">
          <h6>Welcome onboard, {{ tokenDetail.agentName }}</h6>
        </div>
        <div class="p4b__onboarding-subtitle">
          <p>We're excited to have you on board!</p>
          <p>This link will expire in <strong>{{ timeRemaining }}</strong>, so be sure to complete the form as soon as possible. </p>
        </div>
      </div>
    </div>

    <div
      v-if="showMessage"
      class="p4b__bottom-btn"
    >
      <b-button
        variant="link"
        block
        class="p4b__btn-white"
        :to="{ name: 'terms-of-joining-frontend', params: { token: $route.params.token } }"
        :disabled="!showMessage"
      >
        Continue
      </b-button>
    </div>
    <div v-if="expiredLink">
      <div class="container">
        <div class="p4b__onboarding-form-content">
          <div class="p4b__toc-logo">
            <img
              :src="isMobile() ? logoWhiteMobImg : logoWhiteLargeImg"
              alt="Logo"
            >
          </div>
          <div class="p4b__onboarding-form-link-heading">
            <feather-icon
              icon="LinkIcon"
              size="80"
            />
            <h4>This invite has expired</h4>
            <p>Your onboarding link has expired. Please request a new link from your upline to continue the process.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-white.png'),
      // eslint-disable-next-line global-require
      logoWhiteLargeImg: require('@/assets/images/frontend/p4b/logo-white-large.png'),
      // eslint-disable-next-line global-require
      logoWhiteMobImg: require('@/assets/images/frontend/p4b/logo-white-mob.png'),
      showMessage: false,
      expiredLink: false,
      tokenDetail: {},
      timeRemaining: '',
    }
  },
  beforeMount() {
    document.body.classList.add('p4b__bg')
    const { token } = this.$route.params
    if (token) {
      this.$http.get('front-end/agent/onboarding/validate/token', { params: { token } })
        .then(response => {
          if (response.data.success) {
            if (response.data.validToken) {
              this.showMessage = true
              this.tokenDetail = response.data.token || {}
              const timezone = process.env.VUE_APP_TIME_ZONE
              const now = moment().utcOffset(timezone)
              const expiresAt = moment(response.data.token.expiresAt).utcOffset(timezone)
              const duration = moment.duration(expiresAt.diff(now))

              const hours = Math.floor(duration.asHours())
              if (hours >= 24) {
                const days = Math.floor(duration.asDays())
                const remainingHours = duration.subtract(days, 'days').hours()
                this.timeRemaining = `${days} days and ${remainingHours} hrs`
              } else {
                const minutes = duration.minutes()
                this.timeRemaining = `${hours} hrs and ${minutes} mins`
              }
            } else {
              document.body.classList.remove('p4b__bg')
              document.body.classList.add('p4b__bg-black')
              this.expiredLink = true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
}
</script>
